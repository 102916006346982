import React, { useEffect, useState } from "react";
import {
  Alert,
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  TouchableOpacity,
  Platform,
  FlatList,
  ScrollView,
  Dimensions,
} from "react-native";
import FlashcardsSet from "../Objects/FlashcardsSet";
import Card from "../Objects/Card";

type Side = "front" | "back";

const CardFront: React.FC<{
  item: Card;
  onPress: (item: Card, side: Side) => void;
  matchedCards: string[];
  incorrectMatches: [string, Side][];
}> = ({ item, onPress, matchedCards, incorrectMatches }) => {
  const [selected, setSelected] = useState(false);
  const incorrectCard = incorrectMatches.filter((card) => card[0] == item.id);
  const incorrect = incorrectCard.length != 0 && incorrectCard[0][1] == "front";

  useEffect(() => {
    if (incorrect) {
      const timeoutId = setTimeout(() => {
        setSelected(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [incorrectMatches, item.id]);

  return (
    <TouchableOpacity
      onPress={() => {
        onPress(item, "front");
        setSelected(!selected);
      }}
      style={[
        styles.card,
        selected ? styles.selected : null,
        matchedCards.includes(item.id) ? styles.matched : null,
        incorrect ? styles.incorrect : null,
      ]}
    >
      <Text style={styles.term}>{item.front}</Text>
    </TouchableOpacity>
  );
};

const CardBack: React.FC<{
  item: Card;
  onPress: (item: Card, side: Side) => void;
  matchedCards: string[];
  incorrectMatches: [string, Side][];
}> = ({ item, onPress, matchedCards, incorrectMatches }) => {
  const [selected, setSelected] = useState(false);
  const incorrectCard = incorrectMatches.filter((card) => card[0] == item.id);
  const incorrect = incorrectCard.length != 0 && incorrectCard[0][1] == "back";

  useEffect(() => {
    if (incorrect) {
      const timeoutId = setTimeout(() => {
        setSelected(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [incorrectMatches, item.id]);

  return (
    <TouchableOpacity
      onPress={() => {
        onPress(item, "back");
        setSelected(!selected);
      }}
      style={[
        styles.card,
        selected ? styles.selected : null,
        matchedCards.includes(item.id) ? styles.matched : null,
        incorrect ? styles.incorrect : null,
      ]}
    >
      <Text style={styles.definition}>{item.back}</Text>
    </TouchableOpacity>
  );
};

type Props = {
  flashcardsSet: FlashcardsSet;
  modalVisible: string;
  setModalVisible: any;
};

const StudyOptionPopup: React.FC<Props> = ({
  flashcardsSet,
  modalVisible,
  setModalVisible,
}) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [frontCards, setFrontCards] = useState<Card[]>([]);
  const [backCards, setBackCards] = useState<Card[]>([]);
  const [selectedCard, setSelectedCard] = useState<[Card, Side] | null>();
  const [matchedCards, setMatchedCards] = useState<string[]>([]);
  const [incorrectMatches, setIncorrectMatches] = useState<[string, Side][]>(
    []
  );

  useEffect(() => {
    createRandomArrays();
  }, [flashcardsSet.cards]);

  function getRandomItems() {
    const arr: Card[] = [];
    while (arr.length < 6) {
      const randomIndex = Math.floor(Math.random() * cards.length);
      if (!arr.includes(cards[randomIndex])) {
        arr.push(cards[randomIndex]);
      }
    }
    setCards(arr);
    return arr;
  }

  function shuffleArray(arr: Card[]) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  function createRandomArrays() {
    const randomItems = getRandomItems();
    const shuffledItems1 = shuffleArray(randomItems);
    const shuffledItems2 = shuffleArray(randomItems);
    while (shuffledItems1 === shuffledItems2) {
      shuffleArray(shuffledItems2);
    }
    setFrontCards(shuffledItems1);
    setBackCards(shuffledItems2);
  }

  const handleCardPress = (card: Card, side: Side) => {
    if (selectedCard && selectedCard[0].id == card.id) {
      if (selectedCard[1] == side) {
        // No
      } else {
        setMatchedCards([...matchedCards, card.id]);
        setSelectedCard(null);
      }
    } else if (selectedCard && selectedCard[0].id != card.id) {
      // wrong
      setIncorrectMatches([
        [card.id, side],
        [selectedCard[0].id, selectedCard[1]],
      ]);
      setSelectedCard(null);
      setTimeout(() => {
        // Reset selected cards after one second
        setIncorrectMatches([]);
      }, 1500);
    } else {
      setSelectedCard([card, side]);
    }
  };

  const renderCardFront = ({ item, index }: { item: Card; index: number }) => (
    <CardFront
      onPress={handleCardPress}
      matchedCards={matchedCards}
      item={item}
      key={index}
      incorrectMatches={incorrectMatches}
    />
  );

  const renderCardBack = ({ item, index }: { item: Card; index: number }) => (
    <CardBack
      onPress={handleCardPress}
      matchedCards={matchedCards}
      item={item}
      key={index}
      incorrectMatches={incorrectMatches}
    />
  );

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible.length > 0}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible("");
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{flashcardsSet.title}</Text>
            <View
              style={{ width: Dimensions.get("window").width, height: "100%" }}
            >
              <ScrollView>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <FlatList
                    data={frontCards}
                    renderItem={renderCardFront}
                    keyExtractor={(item) => item.id}
                    scrollEnabled={false}
                    extraData={matchedCards}
                  />
                  <FlatList
                    data={backCards}
                    renderItem={renderCardBack}
                    keyExtractor={(item) => item.id}
                    scrollEnabled={false}
                  />
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    width: Dimensions.get("window").width,
    marginTop: Platform.OS == "web" ? undefined : 50,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 30,
    width: Dimensions.get("window").width,
    height: "100%",
    backgroundColor: "#FFFDFA",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.75,
    shadowRadius: 20,
    elevation: 15,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    fontSize: 24,
    fontWeight: "400",
    marginBottom: 15,
    textAlign: "center",
    // color: "#333",
  },
  card: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    margin: 10,
    height: 120,
    width: Dimensions.get("window").width * 0.4,
    justifyContent: "center",
    alignItems: "center",
  },
  term: {
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
  },
  definition: {
    color: "#666",
    fontSize: 14,
    textAlign: "center",
    marginTop: 5,
  },
  selected: {
    borderColor: "#3498db",
    borderWidth: 2,
  },
  incorrect: {
    borderWidth: 2,
    borderColor: "#e74c3c",
    backgroundColor: "#e8c6c6",
  },
  matched: {
    opacity: 0.75,
    borderWidth: 2,
    borderColor: "#2ecc71",
    backgroundColor: "#c7e8c6",
  },
});

export default StudyOptionPopup;
