import React from "react";
import { Platform, View } from "react-native";
import FlashcardsSet from "../Objects/FlashcardsSet";
import StudyComponent from "./StudyComponent";

type Props = {
  set: FlashcardsSet;
  modalVisible: string;
  setModalVisible: any;
};

const StudyOptionsComponent: React.FC<Props> = ({
  set,
  modalVisible,
  setModalVisible,
}) => {
  const studyComponentFlex = Platform.OS == "web" ? 1 / 5 : 1;
  const width = Platform.OS == "web" ? undefined : "90%";
  const studyComponentFlexDirection = Platform.OS == "web" ? "row" : "column";
  return (
    <View
      style={{
        flexDirection: studyComponentFlexDirection,
        width: "100%",
        justifyContent: Platform.OS == "web" ? "space-evenly" : undefined,
      }}
    >
      <View
        style={{ flex: studyComponentFlex, width: width, marginBottom: 15 }}
      >
        <StudyComponent
          flashcardsSet={set}
          studyOption="learn"
          setModalVisible={setModalVisible}
        />
      </View>
      <View
        style={{ flex: studyComponentFlex, width: width, marginBottom: 15 }}
      >
        <StudyComponent
          flashcardsSet={set}
          studyOption="study"
          setModalVisible={setModalVisible}
        />
      </View>
      <View
        style={{ flex: studyComponentFlex, width: width, marginBottom: 15 }}
      >
        <StudyComponent
          flashcardsSet={set}
          studyOption="match"
          setModalVisible={setModalVisible}
        />
      </View>
      <View
        style={{ flex: studyComponentFlex, width: width, marginBottom: 15 }}
      >
        <StudyComponent
          flashcardsSet={set}
          studyOption="test"
          setModalVisible={setModalVisible}
        />
      </View>
    </View>
  );
};

export default StudyOptionsComponent;
