import React from "react";
import AudioPlayer from "./Audio";

const generateRandomId = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";

  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    id += alphabet[randomIndex];
  }

  return id;
};

export default class Card {
  front: string;
  frontAudio: AudioPlayer;
  back: string;
  backAudio: AudioPlayer;
  image: string | null;
  id: string;

  constructor(cardData: any) {
    this.front = cardData.front;
    this.frontAudio = new AudioPlayer(cardData.frontAudio);
    this.back = cardData.back;
    this.backAudio = new AudioPlayer(cardData.backAudio);
    this.image = cardData.image;
    this.id = generateRandomId();
  }
}
