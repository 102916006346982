import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
// @ts-ignore
import FlashcardsSet from "../Objects/FlashcardsSet";
import StudyOption from "../Objects/StudyOptions";

type Props = {
  flashcardsSet: FlashcardsSet;
  studyOption: StudyOption;
  setModalVisible: any;
};

const StudyComponent: React.FC<Props> = ({
  flashcardsSet,
  studyOption,
  setModalVisible,
}) => {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        setModalVisible(studyOption);
      }}
    >
      <Text style={{ fontSize: 15, fontWeight: "400" }}>
        {studyOption.charAt(0).toLocaleUpperCase() + studyOption.slice(1)}
      </Text>
    </TouchableOpacity>
  );
};

export default StudyComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    top: 5,
    padding: Platform.OS == "web" ? undefined : 5,
    alignItems: "center",
    aspectRatio: 2 / 0.5,
    borderRadius: 15,
    borderColor: "grey",
    borderWidth: 1,
    elevation: 15,
    justifyContent: "center",
    backgroundColor: "#FFFDFA",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
});
