import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  Button,
  Dimensions,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  Text,
  View,
  Platform,
} from "react-native";
import Icon from "react-native-vector-icons/AntDesign";
// @ts-ignore
import FlashcardsSet from "../Objects/FlashcardsSet";
import CardSlider from "../Components/SetDisplay";

type Props = {
  flashcardsSet: FlashcardsSet;
};

const SetScreen: React.FC<Props> = ({ flashcardsSet }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showBack, setShowBack] = useState(false);

  const handlePress = () => {
    setShowBack(!showBack);
  };

  const goToNextCard = () => {
    if (currentIndex < flashcardsSet.cards.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowBack(false);
    }
  };

  const goToPreviousCard = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setShowBack(false);
    }
  };

  const [touchX, setTouchX] = useState(0);

  return (
    <View
      style={styles.container}
      onTouchStart={(e) => setTouchX(e.nativeEvent.pageX)}
      onTouchEnd={(e) => {
        const diff = touchX - e.nativeEvent.pageX;
        if (diff > 50) {
          console.log("Swiped left");
          goToNextCard();
        } else if (diff < -50) {
          console.log("Swiped right");
          goToPreviousCard();
        }
      }}
    >
      <View style={styles.setDisplay}>
        <CardSlider
          cardData={flashcardsSet.cards}
          currentIndex={currentIndex}
          showBack={showBack}
          handlePress={handlePress}
        />
      </View>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          position: "absolute",
          alignSelf: "center",
          justifyContent: "space-between",
          padding: 5,
        }}
      >
        <TouchableOpacity
          style={[styles.button, { opacity: currentIndex === 0 ? 0.25 : 1 }]}
          onPress={goToPreviousCard}
          disabled={currentIndex === 0}
        >
          <Icon name="leftcircleo" size={25} />
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.button,
            {
              opacity:
                currentIndex === flashcardsSet.cards.length - 1 ? 0.25 : 1,
            },
          ]}
          onPress={goToNextCard}
          disabled={currentIndex === flashcardsSet.cards.length - 1}
        >
          <Icon name="rightcircleo" size={25} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SetScreen;

const mobile = Platform.OS != "web";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FAF9F6",
    top: 5,
    alignItems: "center",
    // justifyContent: "center",
  },
  setDisplay: {
    height: !mobile ? Dimensions.get("window").height * 0.6 : undefined,
    maxHeight: mobile ? Dimensions.get("window").height * 0.6 : undefined,
    maxWidth: mobile ? Dimensions.get("window").width * 0.9 : undefined,
    aspectRatio: 1.5 / 1,
    borderRadius: 15,
    borderColor: "grey",
    borderWidth: 1,
    elevation: 15,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFDFA",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
  },
  buttonContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: "#FAF9F6",
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 15,
  },
});
