import React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  View,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  Keyboard,
  Alert,
  ScrollView,
  Dimensions,
} from "react-native";
import FlashcardsSet from "./Objects/FlashcardsSet";
import SetScreen from "./Screens/SetScreen";
import StudyOptionsComponent from "./Components/StudyOptionsComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from "react-native-vector-icons/AntDesign";
import Spinner from "react-native-loading-spinner-overlay";
import StudyOptionPopup from "./Components/StudyOptionPupup";

const Title = ({ set }: { set: FlashcardsSet }) => {
  const [starred, setStarred] = useState(false);
  const icon = starred ? "star" : "staro";

  const getStarred = async () => {
    try {
      const json = await AsyncStorage.getItem(set.url);
      setStarred(JSON.parse(json!));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getStarred();
  }, []);

  const updateStarred = async (star: boolean) => {
    try {
      await AsyncStorage.setItem(set.url, JSON.stringify(star));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <View
      style={{
        paddingBottom: 15,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Text
        style={styles.title}
        adjustsFontSizeToFit={true}
        numberOfLines={undefined}
      >
        {set.title}
      </Text>
      <TouchableOpacity
        onPress={() => {
          const newBool = !starred;
          setStarred(newBool);
          updateStarred(newBool);
        }}
      >
        <Icon name={icon} size={25} />
      </TouchableOpacity>
    </View>
  );
};

export default function App() {
  const [url, setUrl] = useState("");
  const [set, setData] = useState<FlashcardsSet>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTime, setLoadingTime] = useState(0);
  const [modalVisible, setModalVisible] = useState("");

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isLoading) {
      intervalId = setInterval(() => {
        setLoadingTime((time) => time + 1);
      }, 1000);
    } else {
      setLoadingTime(0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoading]);

  const getSet = async () => {
    try {
      Keyboard.dismiss();
      setIsLoading(true);
      const regex = /\/(\d+)\//; // regex to match digits between two slashes
      const setId = url.match(regex)![1]; // extract the setId from the matched regex group

      // Check if the set is already cached
      const cachedSet = await AsyncStorage.getItem(`set_${setId}`);
      if (cachedSet) {
        const object = new FlashcardsSet(JSON.parse(cachedSet));
        setData(object);
        setIsLoading(false);
        return;
      }

      const response = await fetch(
        "https://quizlet-get.onrender.com/quizlet-set/" + setId
      );
      const json = await response.json();
      const object = new FlashcardsSet(json);
      console.log(object);
      setData(object);
      console.log(object);

      // Cache the set for future use
      await AsyncStorage.setItem(`set_${setId}`, JSON.stringify(json));

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      Alert.alert(
        "Error",
        "Sorry, an error occurred while processing your request. Please ensure that you have entered a valid Quizlet set URL and try again.",
        [
          {
            text: "OK",
            onPress: () => console.log("OK Pressed"),
            style: "cancel",
          },
        ],
        { cancelable: true }
      );
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        {Platform.OS == "web" && (
          <img
            src={require("./assets/KWIZ.png")}
            style={{ width: "15%", filter: "brightness(0) hue-rotate(210deg)" }}
          />
        )}
        <TextInput
          placeholder="https://quizlet.com/123456789/how-to-use-kwiz"
          placeholderTextColor={"grey"}
          onChangeText={(text) => {
            setUrl(text);
          }}
          onSubmitEditing={() => getSet()}
          style={{
            width: "70%",
            height: "55%",
            paddingHorizontal: 10,
            alignSelf: "center",
            borderRadius: 15,
            borderBottomColor: "grey",
            borderWidth: 1,
          }}
        />
        <TouchableOpacity
          style={{
            width: Platform.OS == "web" ? "10%" : "20%",
            height: "55%",
            alignSelf: "center",
            backgroundColor: "#FAF9F6",
            borderRadius: 15,
            borderBottomColor: "grey",
            borderWidth: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => getSet()}
        >
          <Text>Get Set</Text>
        </TouchableOpacity>
      </View>
      {set && (
        <ScrollView
          horizontal={false}
          directionalLockEnabled={true}
          contentContainerStyle={{
            flexGrow: 1,
            width: Dimensions.get("window").width,
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            alignContent: "center",
          }}
        >
          <View>
            {set && (
              <View style={styles.setScreen}>
                {set && <Title set={set} />}
                {set && <SetScreen flashcardsSet={set} />}
                {set && (
                  <View
                    style={{
                      marginTop: 15,
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    <StudyOptionsComponent
                      set={set}
                      modalVisible={modalVisible}
                      setModalVisible={setModalVisible}
                    />
                  </View>
                )}
              </View>
            )}
            <View style={{ position: "absolute", alignSelf: "center" }}>
              <Spinner
                visible={isLoading}
                textContent={
                  loadingTime <= 5 ? "Loading..." : "This might take a while..."
                }
                textStyle={{ color: "white" }}
              />
            </View>
            <View style={{ position: "absolute", alignSelf: "center" }}>
              <StudyOptionPopup
                flashcardsSet={set}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
              />
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FAF9F6",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    height: 80,
    width: "100%",
    backgroundColor: "#FFFDFA",
    alignItems: "center",
    justifyContent: Platform.OS == "web" ? "space-between" : "space-evenly",
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#CCCCCC",
  },
  setScreen: {
    alignContent: "flex-start",
    alignSelf: "flex-start",
    padding: 25,
  },
  title: {
    width: "80%",
    fontSize: 25,
    fontWeight: "500",
  },
});
